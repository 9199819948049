<template>
    <div>
        <div class="card py-10">
            <div class="row">
                <div class="col mx-2">
                    <p class="titulo">Partes de Viajes</p>
                </div>
            </div>
            <div class="row px-2">
                <div style="display: flex; width:100%; flex-wrap: wrap; padding: 5px 5px;">
                    <button v-for="l in files_partes" :key="l.filename" @click="download(l.link)" style="display:flex; align-items: center; margin: 10px;" class="btn-download" title="Descargar">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                            <rect width="16" height="9" x="28" y="15" fill="#21a366"></rect>
                            <path fill="#185c37" d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"></path>
                            <rect width="16" height="9" x="28" y="24" fill="#107c42"></rect>
                            <rect width="16" height="9" x="12" y="15" fill="#3fa071"></rect>
                            <path fill="#33c481" d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"></path>
                            <path fill="#21a366" d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"></path>
                            <path
                                d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z"
                                opacity=".05"></path>
                            <path
                                d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z"
                                opacity=".07"></path>
                            <path
                                d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z"
                                opacity=".09"></path>
                            <linearGradient id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1" x1="4.725" x2="23.055" y1="14.725"
                                y2="33.055" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#18884f"></stop>
                                <stop offset="1" stop-color="#0b6731"></stop>
                            </linearGradient>
                            <path fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)"
                                d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z">
                            </path>
                            <path fill="#fff"
                                d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z">
                            </path>
                        </svg>
                        {{ l.name }}
                    </button>
                </div>
                
            </div>
        </div>


        <div class="card py-10">
            <div class="row">
                <div class="col mx-2">
                    <p class="titulo">Servicios Wellboats</p>
                </div>
            </div>
            <div class="row px-2">
                <div style="display: flex; width:100%;flex-wrap: wrap; padding: 5px 5px;">
                    <button v-for="l in files_servicios_wellboats" :key="l.filename" @click="download(l.link)" style="display:flex; align-items: center; margin: 10px;" class="btn-download" title="Descargar">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
                            <rect width="16" height="9" x="28" y="15" fill="#21a366"></rect>
                            <path fill="#185c37" d="M44,24H12v16c0,1.105,0.895,2,2,2h28c1.105,0,2-0.895,2-2V24z"></path>
                            <rect width="16" height="9" x="28" y="24" fill="#107c42"></rect>
                            <rect width="16" height="9" x="12" y="15" fill="#3fa071"></rect>
                            <path fill="#33c481" d="M42,6H28v9h16V8C44,6.895,43.105,6,42,6z"></path>
                            <path fill="#21a366" d="M14,6h14v9H12V8C12,6.895,12.895,6,14,6z"></path>
                            <path
                                d="M22.319,13H12v24h10.319C24.352,37,26,35.352,26,33.319V16.681C26,14.648,24.352,13,22.319,13z"
                                opacity=".05"></path>
                            <path
                                d="M22.213,36H12V13.333h10.213c1.724,0,3.121,1.397,3.121,3.121v16.425	C25.333,34.603,23.936,36,22.213,36z"
                                opacity=".07"></path>
                            <path
                                d="M22.106,35H12V13.667h10.106c1.414,0,2.56,1.146,2.56,2.56V32.44C24.667,33.854,23.52,35,22.106,35z"
                                opacity=".09"></path>
                            <linearGradient id="flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1" x1="4.725" x2="23.055" y1="14.725"
                                y2="33.055" gradientUnits="userSpaceOnUse">
                                <stop offset="0" stop-color="#18884f"></stop>
                                <stop offset="1" stop-color="#0b6731"></stop>
                            </linearGradient>
                            <path fill="url(#flEJnwg7q~uKUdkX0KCyBa_UECmBSgBOvPT_gr1)"
                                d="M22,34H6c-1.105,0-2-0.895-2-2V16c0-1.105,0.895-2,2-2h16c1.105,0,2,0.895,2,2v16	C24,33.105,23.105,34,22,34z">
                            </path>
                            <path fill="#fff"
                                d="M9.807,19h2.386l1.936,3.754L16.175,19h2.229l-3.071,5l3.141,5h-2.351l-2.11-3.93L11.912,29H9.526	l3.193-5.018L9.807,19z">
                            </path>
                        </svg>
                        {{ l.name }}
                    </button>
                </div>
                
            </div>
        </div>


    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data: () => ({
        files_partes: [],
        files_servicios_wellboats: [],
    }),
    async mounted() {
        await this.loadFilesPartes();
        await this.loadFilesServiciosWellboats();
    },
    methods: {
        async loadFilesPartes() {
            let url = `${this.base_url}cargar/reportes/partes`;
            await this.axios.get(url, this.headers).then(r => {
                this.files_partes = r.data
            })
        },
        async loadFilesServiciosWellboats() {
            let url = `${this.base_url}cargar/reportes/servicios_wellboats`;
            await this.axios.get(url, this.headers).then(r => {
                this.files_servicios_wellboats = r.data
            })
        },
        download(link){
            window.open(link)
        }
    },
    computed: {
        ...mapState(['base_url', 'headers'])
    }
}
</script>
<style>
.btn-download {
    background-color: #f8f9fa;
    border-radius: 0.5em;
    padding: 10px;
}

.btn-download:hover {
    background-color: #dedfe0;
}

.titulo{
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    font-size: 2em;
    font-weight: 500;
}
</style>